<template>
	<div class="about">
		<div class="banner">
			<img src="@/assets/about/log.png" alt="">
		</div>
		<img src="@/assets/about/ProductsTop.png" alt="" class="AboutUs">
		<div class="AboutUsText">
			Yeah Fresh is a grocery chain that was founded in 2021 during the peak of the pandemic at the heart
			of Metro Manila in Manila City that has two (2) shops located in the city of Mandaluyong and BGC
			that strives every day to ensure every customer receives the best quality of local and international
			goods, fresh vegetables, fruits, seafood, meats, and other essential products at an affordable price
			while providing the customer a unique, friendly and convenient purchase and delivery experience with
			technology development, strong management, and dedicated employees.
		</div>
		<div class="one">
			<div class="item">
				<div class="img">
					<img src="@/assets/about/FreshIngredients.png" alt="">
				</div>
				<div class="txt">
					<div class="">
						Fresh Ingredients
					</div>
					<div class="">
						With the highest quality control standards over products procurement, warehousing,
						fulfillment, and store checks, we provide the best quality of products and customer
						services.
					</div>
				</div>
			</div>
			<div class="item">
				<div class="txt">
					<div class="">
						Cold Chain Logistics
					</div>
					<div class="">
						Direct to consumer and farm-to-table with professional cold chain logistics, Yeah Fresh
						delivers the best quality ingredients to you.
					</div>
				</div>
				<div class="img">
					<img src="@/assets/about/ColdChains.png" alt="">
				</div>
			</div>
			<div class="item">
				<div class="img">
					<img src="@/assets/about/CustomerSeerrvice.png" alt="">
				</div>
				<div class="txt">
					<div class="">
						Customer Service
					</div>
					<div class="">
						If there is any quality issue, you may apply for an exchange or refund within 24 hours from
						the time of delivery.
					</div>
				</div>
			</div>
		</div>
		<div class="list">
			<div class="item">
				<img src="@/assets/about/bc.png">
				<div class="bt">
					Products
				</div>
				<div class="dis">
					From healthy fruits to fresh seafood to delicious poultries to essential bread and flowers to show
					affection to your family, friends, and loved ones, we cater to all your fresh needs.
				</div>
				
			</div>
			<div class="item">
				<img src="@/assets/about/mg.png">
				<div class="bt">
					Mission
				</div>
				<div class="dis">
					Yeah Fresh provides a unique online and offline shopping experience to our partners and customers by
					providing international goods and high-quality fresh products while helping our local agricultural
					industry as our resource.
				</div>
				
			</div>
			<div class="item">
				<img src="@/assets/about/xhs.png">
				<div class="bt">
					Vision
				</div>
				<div class="dis">
					To become globally number 1 Asian grocery chain in supplying their daily needs by providing a
					variety of local and international high-quality fresh produce products
				</div>
				
			</div>
		</div>
	
		<foot></foot>
	</div>
</template>

<script>
	import foot from "@/components/topBot/footer.vue"
	export default {
		name: "about",
		components: {
			foot
		},
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		destroyed() {

		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped="scoped">
	.banner {
		width: 1920px;
		height: 506px;
		margin-bottom: 100px;
		background: url(../assets/about/bannerBg.jpg);
	}

	.banner img {
		display: block;
		margin: 0 auto;
		padding-top: 195px;
	}

	.AboutUs {
		display: block;
		margin: 0 auto;
		margin-top: -295px;
	}

	.AboutUsText {
		width: 1200px;
		margin: 0 auto;
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		text-align: center;
		padding: 90px 0;
	}

	.one {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 70px;
		padding-top: 30px;
	}

	.one .item {
		display: flex;
		align-items: center;
		padding-bottom: 70px;
	}
	.one .item .img{
		width: 600px;
	}
	.one .item .txt{
		width: 600px;
	}
	.one .item .txt div:nth-child(1) {
		font-size: 24px;
		font-weight: 400;
		color: #333333;
	}

	.one .item .txt div:nth-child(2) {
		font-size: 16px;
		font-weight: 400;
		color: #666666;
	}

	.list {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 200px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.list .item {
		width: 339px;
	}

	.list .item div.bt {
		font-size: 24px;
		font-weight: 400;
		color: #333333;
		text-align: center;
	}

	.list .item div.dis {
		padding: 0 25px 0 25px;
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		text-align: center;
	}

	.list .item img {
		width: 339px;
		height: 220px;
		display: block;
		margin-bottom: 50px;
	}

</style>
